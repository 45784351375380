function domReady (fn) {
  // If we're early to the party
  document.addEventListener('DOMContentLoaded', fn)
  // If late; I mean on time.
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    fn()
  }
}

function burger () {
  const burger = document.querySelector('.burger')
  burger.classList.add('unToggled')

  burger.addEventListener('click', function () {
    burger.classList.toggle('toggled')
    burger.classList.toggle('unToggled')
  })
}

function animate () {
  const OFFSET = 0
  const ANIMATIONS_OUT = {
    rollin: 'rollout',
    fadein: 'fadeout'
  }
  const elements = document.querySelectorAll('[data-animation]')
  const animations = []
  const animated = []

  for (const element of elements) {
    const animationName = element.dataset.animation
    const removeName = ANIMATIONS_OUT[animationName]

    if (!removeName) {
      console.warn('animation error (no animation out)', element)
      continue
    }

    const animationOffset = Number(element.dataset.animationOffset) || 0
    const offsetTop = element.getBoundingClientRect()?.top

    animations.push({
      element,
      animationName,
      removeName,
      offsetTop,
      animationStart: offsetTop && Math.ceil(offsetTop) - OFFSET + animationOffset
    })
  }

  function updateAnimations () {
    const screenHeight = window.innerHeight
    const scrolled = window.scrollY

    for (let index = 0; index < animations.length; index++) {
      const animation = animations[index]
      const { element, animationName, removeName, animationStart } = animation
      if (scrolled >= animationStart - screenHeight && !animated.includes(index)) {
        element.classList.add(animationName)
        element.classList.remove(removeName)
        animated.push(index)
      }
    }
  }

  updateAnimations()
  window.addEventListener('scroll', updateAnimations)
}

function scrollTop () {
  const element = document.getElementById('scroll-top')

  if (!element) return

  function handler () {
    const screenHeight = window.innerHeight
    const scrolled = window.scrollY

    if (scrolled > (screenHeight / 2)) {
      element.classList.remove('hidden')
    } else {
      element.classList.add('hidden')
    }
  }

  window.addEventListener('scroll', handler)
}

function landing () {
  const landing = document.getElementById('landing-bg')
  const header = document.querySelector('header')

  if (!landing || !header) return

  function handler () {
    const screenHeight = window.innerHeight
    const scrolled = window.scrollY
    const CLASSNAME = 'scrolled'

    if (scrolled > screenHeight) {
      landing.classList.add(CLASSNAME)
      header.classList.add(CLASSNAME)
    } else {
      landing.classList.remove(CLASSNAME)
      header.classList.remove(CLASSNAME)
    }
  }

  window.addEventListener('scroll', handler)
  handler()
}

function gallery () {
  const modal = document.getElementById('gallery-modal')
  const mainImage = document.getElementById('zoomed-image')
  const closeButton = document.getElementById('close-modal')

  if (!modal || !mainImage || !closeButton) return

  const closeModal = function () {
    modal.classList.remove('opened')
    mainImage.removeAttribute('src')
  }

  closeButton.addEventListener('click', function () {
    closeModal()
  })

  modal.addEventListener('click', function (e) {
    if (e.target.classList.contains('outer') || e.target.classList.contains('inner')) {
      closeModal()
    }
  })

  const images = document.querySelectorAll('#gallery .gallery-grid img')
  if (!images) return

  for (const image of images) {
    image.addEventListener('click', function (e) {
      const sibling = e.target.parentNode.nextElementSibling
      const source = sibling.querySelector('source')
      const srcset = source.srcset

      mainImage.setAttribute('src', srcset)
      modal.classList.add('opened')
    })
  }
}

function contact () {
  const contactForm = document.getElementById('contact_form')
  const formError = document.getElementById('form_error')

  if (!contactForm) return

  function handleSubmit (e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)

    formError.innerHTML = ''

    if (data?.name && data?.email && data?.message) {
      // const url = 'https://kyoho-mail.free.beeceptor.com'
      const url = './mailform/sendmail.php'
      contactForm.classList.add('loading')

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(function (response) {
          if (response.ok) {
            contactForm.innerHTML = 'メールを送信しました'
          } else {
            formError.innerHTML = '送信できませんでした'
          }
          contactForm.classList.remove('loading')
        })
        .catch(function () {
          formError.innerHTML = '送信できませんでした'
          contactForm.classList.remove('loading')
        })
    }
  }

  contactForm.addEventListener('submit', handleSubmit)
}

domReady(() => {
  burger()

  scrollTop()

  landing()

  gallery()

  contact()

  setTimeout(() => {
    animate()
  }, 100)
})
